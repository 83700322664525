html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  font-size: 16px;
}

* {
  outline: none;
}

.logs {
  vertical-align: top;
}

.log * {
  display: inline-block;
  margin-right: 5px;
}

.deleted-extract:not(:hover) {
  opacity: 0.4;
  background: #e2e2e2;
}

.ant-table {
  background: #fff;
}

.ant-form-item {
  margin-bottom: 14px;
}

.ant-form-item-label {
  line-height: 22px;
}

.missionHeader label {
  display: block;
  margin: 5px;
}

.missionHeader label span  * + * {
  margin-left: 5px;
}

.missionHeader label > span:first-child{
  display: inline-block;
  width: 100px;
  text-align: right;
  padding-right: 10px;
}

td.action-list > * + *,
.ant-btn + .ant-btn {
  margin-left: 10px;
}

.ant-btn.btn-green:not([disabled]) {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.ant-btn.btn-volcano:not([disabled]) {
  color: #fff;
  background-color: #ff7a45;
  border-color: #ff7a45;
}

.ant-btn.btn-red {
  margin-right: 50px;
}

.ant-btn.btn-red:not([disabled]) {
  color: #fff;
  background-color: #f5222d;
  border-color: #f5222d;
}

.ant-btn.btn-red:not(:hover) {
  background-color: #b5b6ba;
  border-color: #a9a9a9;
  opacity: 0.4;
}

#generate:not(.hasRemainingData):not(:hover) {
  opacity: 0.4;
}

.extract-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.extract-buttons >*:last-child{
  margin-left: 50px;
}

.download-extract-btn {
  width: 180px;
  text-align: left;
}

.download-extract-btn span {
  font-size: 13px;
}

.download-extract-btn span + span{
  font-size: 9.5px;
  margin-left: 5px;
  color: #a2a2a2;
  letter-spacing: 0;
}
